import React from "react"
import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import Newsletter from "../components/Newsletter/Newsletter"
import Hero from "../components/Hero/Hero"
import { StaticQuery, graphql } from "gatsby"
import TwoUp from "../components/TwoUp/TwoUp"
import Img from "gatsby-image"

const HowToListenPage = props => (
  <div>
    This is a test
  </div>
)

export default HowToListenPage
